<script setup lang="ts">
import { translator } from '@olyslager/shared-components'

import LegendBadge from './LegendBadge.vue'

const { t } = translator.useI18n()
</script>

<template>
  <div class="legend-guide">
    <div>
      <LegendBadge type="vrn" />
      <p>{{ t('country-language.legend-plate', 'License plate search available') }}</p>
    </div>

    <div>
      <LegendBadge type="vin" />
      <p>{{ t('country-language.legend-vin', 'VIN search available') }}</p>
    </div>

    <div>
      <LegendBadge type="hsntsn" />
      <p>{{ t('country-language.legend-hsntsn', 'HSN TSN search available') }}</p>
    </div>

    <div>
      <LegendBadge type="natcode" />
      <p>{{ t('country-language.legend-natcode', 'Nationaler Code search available') }}</p>
    </div>

    <div>
      <LegendBadge type="typengenehmigung" />
      <p>{{ t('country-language.legend-typengenehmigung', 'Typengenehmigung search available') }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.legend-guide {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    font-size: .9em;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
