import { CountryExpanded, RegionCode, countries as countriesUtils } from '@olyslager/global-utilities'

export function getCountriesWithinRegion (countries: CountryExpanded[], region: RegionCode): CountryExpanded[] {
  const countriesWithinRegion: CountryExpanded[] = []
  const regionContinents = countriesUtils.getContinentsFromRegion(region)

  countries.forEach((country) => {
    if (regionContinents.includes(country.continent)) {
      countriesWithinRegion.push(country)
    }
  })

  return countriesWithinRegion
}
