import { Ref, computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { CountryExpanded, Language, RegionCode, countries, languages } from '@olyslager/global-utilities'

export const useCountryStore = defineStore('country', () => {
  const userCountry: Ref<CountryExpanded> = ref(countries.unknownCountry)
  const userRegion: Ref<RegionCode> = ref(countries.getRegionFromContinent(userCountry.value.continent))
  const userLanguage: Ref<Language> = ref(languages.getCurrentLanguage())

  // actions
  const setUserCountry = (country: CountryExpanded) => {
    userCountry.value = country
  }

  const setUserRegion = (region: RegionCode) => {
    userRegion.value = region
  }

  const setUserLanguage = (language: Language) => {
    userLanguage.value = language
  }

  // getters
  const currentUserCountry = computed<CountryExpanded>(() => {
    return userCountry.value
  })

  const currentUserRegion = computed<RegionCode>(() => {
    return userRegion.value
  })

  const currentUserLanguage = computed<Language>(() => {
    return userLanguage.value
  })

  return {
    userCountry,
    setUserCountry,
    currentUserCountry,
    userRegion,
    setUserRegion,
    currentUserRegion,
    userLanguage,
    setUserLanguage,
    currentUserLanguage
  }
})
