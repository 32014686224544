import { CountryCode, siteDataConfigurationCache } from '@olyslager/global-utilities'

/**
 * Returns all countries found in dataConfig countrymaps regardless of dataset in a single array. Does not remove duplicates.
 * @returns array of iso2 country codes
 */
export const getSiteDataConfigCountries = (): CountryCode[] => {
  const siteDataConfig = siteDataConfigurationCache.get()

  if (!siteDataConfig || !siteDataConfig?.countryMap || siteDataConfig.countryMap.length === 0) return []

  const foundCountries: CountryCode[] = []

  siteDataConfig.countryMap.forEach((map) => {
    if (map.countries.length > 0) {
      foundCountries.push(...map.countries as CountryCode[])
    }
  })

  return foundCountries
}
