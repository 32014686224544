<script setup lang="ts">
import { Ref, inject, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { CountryCode, CountryExpanded, countries, languages } from '@olyslager/global-utilities'
import { OlyButtonV2, translator } from '@olyslager/shared-components'

import { getSiteDataConfigCountries } from '@/composables'
import { useCountryStore } from '@/stores/country'

import LegendGuide from '@/components/legend/LegendGuide.vue'
import CountrySelection from '@/components/selections/country/CountrySelection.vue'
import LanguageSelection from '@/components/selections/language/LanguageSelection.vue'
import RegionSelection from '@/components/selections/region/RegionSelection.vue'

const route = useRoute()
const router = useRouter()
const { t } = translator.useI18n()
const countryStore = useCountryStore()
const availableCountries: Ref<CountryExpanded[]> = ref([])

// allowed countries and sitedataconfig from config
const allowedCountries: CountryCode[] | undefined = inject('AllowedCountries')

onMounted(() => {
  initializeUserCountry()
  availableCountries.value = getAvailableCountries()
})

const initializeUserCountry = () => {
  const currentCountry = countries.getCurrentCountry()

  if (!currentCountry) {
    countryStore.setUserCountry(countries.unknownCountry)
    countryStore.setUserRegion(countries.getRegionFromContinent(countries.unknownCountry.continent))
    return
  }

  // store country and region in pinia store
  countryStore.setUserCountry(currentCountry)
  countryStore.setUserRegion(countries.getRegionFromContinent(currentCountry.continent))
}

const getAvailableCountries = (): CountryExpanded[] => {
  const allCountries: CountryCode[] = []
  const returnCountries: CountryExpanded[] = []

  // get default country fetched based in IP
  const defaultCountry = countries.getDefaultCountry()
  if (defaultCountry && defaultCountry.iso2 !== countries.unknownCountry.iso2) {
    allCountries.push(defaultCountry.iso2)
  }

  // get dataset countries
  const dataSetCountries = getSiteDataConfigCountries()

  // add all allowed countries from config if they exist
  if (allowedCountries && allowedCountries.length > 0) {
    allowedCountries.forEach((country) => {
      if (!allCountries.includes(country)) {
        allCountries.push(country)
      }
    })
  }

  // add all dataset countries from data config if they exist
  if (dataSetCountries.length > 0) {
    dataSetCountries.forEach((country) => {
      if (!allCountries.includes(country)) {
        allCountries.push(country)
      }
    })
  }

  // if no countries were found
  if (allCountries.length === 0) {
    // only add user country or unknown country
    if (countryStore.currentUserCountry.iso2 !== countries.unknownCountry.iso2) {
      returnCountries.push(countryStore.currentUserCountry)
    } else {
      returnCountries.push(countries.unknownCountry)
    }

    return returnCountries
  }

  // get expanded data for each unique country
  allCountries.forEach((country) => {
    returnCountries.push(countries.getExpandedCountryData(country))
  })

  // sort alphabetically
  returnCountries.sort((a, b) => a.name.localeCompare(b.name))

  // add unknown country if countries were found but no user country was found
  if (countryStore.currentUserCountry.iso2 === countries.unknownCountry.iso2) {
    returnCountries.push(countryStore.currentUserCountry)
  }

  return returnCountries
}

const confirmSelection = () => {
  // set vrn country if country was changed
  if (countries.getCurrentCountry()?.iso2 !== countryStore.currentUserCountry.iso2) {
    countries.setCurrentVrnCountry(countryStore.currentUserCountry.iso2)
  }

  // set selected country in localStorage
  countries.setCurrentCountry(countryStore.currentUserCountry.iso2)

  const previousLanguage = languages.getCurrentLanguage()

  // if no history is found, redirect to root
  if (!window.history.state.back) {
    window.location.href = '/' + countryStore.currentUserLanguage.code2
    return
  }

  // if a history is found, replace the language in the previous path and redirect
  window.location.href = window.location.origin + languages.replaceLanguageInPath(window.history.state.back, previousLanguage, countryStore.currentUserLanguage)
}

const goBack = () => {
  if (window.history.state.back) {
    router.back()
    return
  }

  router.push('/' + route.params.locale)
}
</script>

<template>
  <div class="country-language-selection">
    <div class="country-language-selection__title">
      <h1>{{ t('country-language.header', 'Select your location and language') }}</h1>
    </div>

    <div class="country-language-selection__region">
      <RegionSelection
        :available-countries="availableCountries"
      />
    </div>

    <div class="country-language-selection__countries">
      <CountrySelection
        :available-countries="availableCountries"
      />
    </div>

    <div class="country-language-selection__languages">
      <LanguageSelection
        :languages="languages.getLanguages()"
      />
    </div>

    <div class="country-language-selection__footer">
      <LegendGuide />
      <div class="buttons">
        <OlyButtonV2
          border
          class="buttons__button-cancel"
          @click="goBack"
        >
          {{ t('country-language.cancel', 'Cancel') }}
        </OlyButtonV2>
        <OlyButtonV2
          border
          class="buttons__button-confirm"
          @click="confirmSelection"
        >
          {{ t('country-language.confirm', 'Confirm') }}
        </OlyButtonV2>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@olyslager/shared-components/scss';

.country-language-selection {
  background-color: var(--background, var(--color-primary));
  color: var(--text, var(--color-secondary));
  padding: 20px;
  border-radius: var(--border-radius, 8px);
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 30px;

  &__title {
    text-align: center;

    & h1 {
      text-transform: uppercase;
      font-size: 2em;
      color: var(--header-text, var(--color-secondary));
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .buttons {
      display: flex;
      gap: 20px;
      align-self: flex-end;
      &__button {
        &-cancel {
          --border: var(--button-cancel-border, var(--color-tertiary));
          --background: var(--button-cancel-background, #ffffff00);
          --text: var(--button-cancel-text, var(--color-secondary));
          --active-border: var(--button-cancel-active-border, #ffffff00);
          --active-background: var(--button-cancel-active-background, var(--color-tertiary));
          --active-text: var(--button-cancel-active-text, var(--color-primary));
        }
        &-confirm {
          --border: var(--button-confirm-border, var(--color-tertiary));
          --background: var(--button-confirm-background, #ffffff00);
          --text: var(--button-confirm-text, var(--color-secondary));
          --active-border: var(--button-confirm-active-border, #ffffff00);
          --active-background: var(--button-confirm-active-background, var(--color-tertiary));
          --active-text: var(--button-confirm-active-text, var(--color-primary));
        }
      }
    }
  }
}

@include large() {
  .country-language-selection {
    width: 90%;
    margin: 0 auto;
  }

  .country-language-selection {
    &__footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
</style>
