<script setup lang="ts">
import { PropType, Ref, computed, ref, watch } from 'vue'

import { CountryExpanded } from '@olyslager/global-utilities'

import { getCountriesWithinRegion } from '@/composables'
import { useCountryStore } from '@/stores/country'

import CountryOption from './CountryOption.vue'

const props = defineProps({
  availableCountries: {
    type: Array as PropType<CountryExpanded[]>,
    required: true
  }
})

const countryStore = useCountryStore()
const countriesInUserRegion: Ref<CountryExpanded[]> = ref([])
const selectedCountry: Ref<CountryExpanded | null> = ref(null)

// get available countries within the current region
watch(() => props.availableCountries, (countries) => {
  if (countries.length > 0) {
    countriesInUserRegion.value = getCountriesWithinRegion(props.availableCountries, countryStore.currentUserRegion)
    selectedCountry.value = countryStore.currentUserCountry
  }
}, { immediate: true })

// update countries on region change
watch(() => countryStore.userRegion, (region) => {
  countriesInUserRegion.value = getCountriesWithinRegion(props.availableCountries, region)
})

const countrySelected = (country: CountryExpanded) => {
  selectedCountry.value = country
  countryStore.setUserCountry(country)
}

const compactCountries = computed(() => {
  return countriesInUserRegion.value.length < 5
})
</script>

<template>
  <div
    class="countries-selection"
    :class="{ 'compact-countries': compactCountries }"
  >
    <CountryOption
      v-for="country in countriesInUserRegion"
      :key="country.iso2"
      class="country"
      :country="country"
      :is-active="selectedCountry?.iso2 === country.iso2"
      @select="countrySelected(country)"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@olyslager/shared-components/scss';

.countries-selection {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  .country {
    --text: var(--region-text, var(--color-secondary));
    --border: var(--region-border, var(--color-tertiary));
  }
}

@include small() {
  .countries-selection {
    grid-template-columns: repeat(2, 1fr);

    &.compact-countries {
      grid-template-columns: 1fr;
    }
  }
}

@include large() {
  .countries-selection {
    grid-template-columns: repeat(3, 1fr);

    &.compact-countries {
      grid-template-columns: 1fr;
    }
  }
}
</style>
