<script setup lang="ts">
import { PropType, provide } from 'vue'

import { SiteConfiguration } from '@olyslager/customer-configuration'

import { useFrontendCssVars } from '@/composables/useFrontendCssVars'

const props = defineProps({
  siteConfiguration: {
    type: Object as PropType<SiteConfiguration>,
    required: true
  }
})

// eslint-disable-next-line no-undef
const frontend = props.siteConfiguration.frontends.find(f => f.name === APP_NAME)
const frontendVehicleSearch = props.siteConfiguration.frontends.find(f => f.name === '@olyslager/vehicle-search')

if (frontend) {
  provide('Config', frontend)
  provide('AllowedCountries', frontendVehicleSearch?.properties.allowedCountries)
  useFrontendCssVars()
}

</script>

<template>
  <router-view />
</template>
