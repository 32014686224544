import { App, createApp, h } from 'vue'
import { createPinia } from 'pinia'
import singleSpaVue from 'single-spa-vue'

import { SiteConfiguration } from '@olyslager/customer-configuration'
import { translator } from '@olyslager/shared-components'

import createRouter from './router'

import myApp from './App.vue'

// eslint-disable-next-line no-console
console.debug(`${APP_NAME}: v${APP_VERSION}`)

let siteConfiguration: SiteConfiguration

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    name: 'Country & Language Selection',
    render () {
      return h(myApp, {
        siteConfiguration
      })
    }
  },
  handleInstance (app: App) {
    const i18n = translator.createI18n({
      debuggerProjectName: APP_NAME
    })
    app.use(i18n)
    app.use(createRouter(i18n))
    app.use(createPinia())
  }
})

interface SingleSpaProps {
  siteConfiguration: SiteConfiguration;
}

export async function bootstrap (singleSpaProps: SingleSpaProps) {
  siteConfiguration = singleSpaProps.siteConfiguration
}

export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
