import { DefaultColor } from '@olyslager/customer-configuration'
import { useCreateFrontendCssVars } from '@olyslager/shared-components'

const colorMap: Map<string, DefaultColor | string> = new Map([
  // Main panel
  ['background', 'primary'],
  ['text', 'secondary'],
  ['header-text', 'secondary'],
  // Region selection
  ['region-text', 'secondary'],
  ['region-border', 'tertiary'],
  // Country selection
  ['country-text', 'secondary'],
  ['country-border', 'tertiary'],
  ['legend-vrn-background', 'primary'],
  ['legend-vrn-text', 'secondary'],
  ['legend-vrn-border', 'secondary'],
  ['legend-vin-background', 'primary'],
  ['legend-vin-text', 'secondary'],
  ['legend-vin-border', 'secondary'],
  ['legend-hsntsn-background', 'primary'],
  ['legend-hsntsn-text', 'secondary'],
  ['legend-hsntsn-border', 'secondary'],
  ['legend-natcode-background', 'primary'],
  ['legend-natcode-text', 'secondary'],
  ['legend-natcode-border', 'secondary'],
  ['legend-typengenehmigung-background', 'primary'],
  ['legend-typengenehmigung-text', 'secondary'],
  ['legend-typengenehmigung-border', 'secondary'],
  // Language selection
  ['language-text', 'secondary'],
  ['language-border', 'tertiary'],
  // Cancel button
  ['button-cancel-border', 'tertiary'],
  ['button-cancel-background', '#ffffff00'],
  ['button-cancel-text', 'secondary'],
  ['button-cancel-active-border', '#ffffff00'],
  ['button-cancel-active-background', 'tertiary'],
  ['button-cancel-active-text', 'primary'],
  // Confirm button
  ['button-confirm-border', 'tertiary'],
  ['button-confirm-background', '#ffffff00'],
  ['button-confirm-text', 'secondary'],
  ['button-confirm-active-border', '#ffffff00'],
  ['button-confirm-active-background', 'tertiary'],
  ['button-confirm-active-text', 'primary']
])

export const useFrontendCssVars = () => {
  useCreateFrontendCssVars({
    frontendName: APP_NAME,
    colorMap
  })
}
