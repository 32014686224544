import { Ref, ref } from 'vue'
import { defineStore } from 'pinia'

import { RootProperties, SiteConfiguration, defaults } from '@olyslager/customer-configuration'
import { eventEmitter, siteConfigurationCache } from '@olyslager/global-utilities'

export const useSiteConfigurationStore = defineStore('siteConfiguration', () => {
  const siteConfiguration: Ref<SiteConfiguration | undefined> = ref()
  const rootProperties: Ref<RootProperties> = ref(defaults.properties)

  const refreshConfiguration = () => {
    const updatedSiteConfiguration = siteConfigurationCache.get()
    siteConfiguration.value = updatedSiteConfiguration
    if (updatedSiteConfiguration) {
      rootProperties.value = { ...defaults.properties, ...updatedSiteConfiguration.properties }
    }
  }

  eventEmitter.on('refreshFrontend', (event) => {
    if (event.frontendName === 'all' || event.frontendName === APP_NAME) {
      refreshConfiguration()
    }
  })
  refreshConfiguration()

  return { siteConfiguration, rootProperties }
})
