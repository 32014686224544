<script setup lang="ts">
import { PropType, Ref, ref } from 'vue'

import { Language } from '@olyslager/global-utilities'
import { translator } from '@olyslager/shared-components'

import { useCountryStore } from '@/stores/country'

import TextOption from '@/components/elements/TextOption.vue'

const props = defineProps({
  languages: {
    type: Array as PropType<Language[]>,
    required: true
  }
})

const { t } = translator.useI18n()
const countryStore = useCountryStore()
const selectedLanguage: Ref<Language> = ref(countryStore.currentUserLanguage)

const getLanguages = () => {
  return [...props.languages].sort((a, b) => a.localName.localeCompare(b.localName))
}

const languageSelected = (language: Language) => {
  selectedLanguage.value = language
  countryStore.setUserLanguage(language)
}
</script>

<template>
  <div class="language-selection">
    <p class="language-selection__title">
      {{ t('country-language.language', 'Language') }}
    </p>
    <div class="languages">
      <TextOption
        v-for="language of getLanguages()"
        :key="language.code2"
        class="language"
        :text="language.localName"
        :is-active="selectedLanguage.code2 === language.code2"
        @click="languageSelected(language)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@olyslager/shared-components/scss';

.language-selection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px;

  &__title {
    text-transform: uppercase;
    font-weight: 700;
  }

  .languages {
    display: flex;
    flex-direction: column;
    gap: 10px 20px;

    .language {
      --text: var(--language-text, var(--color-secondary));
      --border: var(--language-border, var(--color-tertiary));
    }
  }
}

@include small() {
  .language-selection {
    .languages {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
</style>
