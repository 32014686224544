<script setup lang="ts">
import { PropType, Ref, computed, ref, watch } from 'vue'

import { CountryExpanded, RegionCode } from '@olyslager/global-utilities'
import { translator } from '@olyslager/shared-components'

import { useCountryStore } from '@/stores/country'

import TextOption from '@/components/elements/TextOption.vue'

const props = defineProps({
  availableCountries: {
    type: Array as PropType<CountryExpanded[]>,
    required: true
  }
})

const { t } = translator.useI18n()
const countryStore = useCountryStore()
const selectedRegion: Ref<RegionCode> = ref('GLOBAL')
const regions: Ref<RegionCode[]> = ref(['GLOBAL'])

const defaultTranslations = {
  EU: 'Europe',
  AM: 'Americas',
  AF: 'Africa',
  AS: 'Asia',
  OC: 'Oceania',
  GLOBAL: 'Global'
}

// get available regions from available countries
watch(() => props.availableCountries, (countries) => {
  if (countries.length > 0) {
    const foundRegions: RegionCode[] = []

    // always have global as option
    foundRegions.push('GLOBAL')

    countries.forEach((country) => {
      if (!foundRegions.includes(country.region)) {
        foundRegions.push(country.region)
      }
    })

    regions.value = foundRegions.sort()
    selectedRegion.value = countryStore.currentUserRegion
  }
}, { immediate: true })

const regionSelected = (region: RegionCode) => {
  selectedRegion.value = region
  countryStore.setUserRegion(region)
}

const compactRegions = computed(() => {
  return regions.value.length < 4
})
</script>

<template>
  <div
    class="region-selection"
    :class="{ 'compact-regions': compactRegions }"
  >
    <TextOption
      v-for="region of regions"
      :key="region"
      class="region"
      :text="t(`regions.${region}`, defaultTranslations[region])"
      :uppercase="true"
      :font-weight="700"
      :is-active="selectedRegion === region"
      @click="regionSelected(region)"
    />
  </div>
</template>

<style scoped lang="scss">
@import '@olyslager/shared-components/scss';

.region-selection {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  padding-block: 10px;
  border-bottom: 1px solid #DEDEDE;

  &.compact-regions {
    justify-content: flex-start;

    .region {
      margin-right: 2em;
    }
  }

  .region {
    --text: var(--region-text, var(--color-secondary));
    --border: var(--region-border, var(--color-tertiary));
  }
}

@include large() {
  .region-selection {
    padding-inline: 100px;
    justify-content: space-between;
  }
}
</style>
